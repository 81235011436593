import api from "@/api";

const backendToFrontendFormatter = (originalData) => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};
const frontendToBackendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

export default {
	state() {
		return {
			tableHeaders: ["Name", "Mobile", "Email", "Role"],
			tableData: [],
			userDetails: {},
		};
	},
	mutations: {
		updateAppUser(state, { userList }) {
			state.tableData = [];
			userList.results.forEach((item) =>
				state.tableData.push(backendToFrontendFormatter(item))
			);
			state.isLoaded = true;
		},
		updateAppUserDetails(state, userDetails) {
			let data = backendToFrontendFormatter(userDetails);
			state.userDetails = data;
			const index = state.tableData.findIndex(
				(item) => item.id === userDetails.id
			);
			if (index !== -1) {
				state.tableData.splice(index, 1, data);
			} else {
				state.tableData.push(data);
			}
		},
		deleteAppUser(state, userID) {
			const index = state.tableData.findIndex(
				(item) => item.id == userID
			);
			if (index !== -1) {
				state.tableData.splice(index, 1);
			}
		},

	},
	actions: {
		fetchAppUsers({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.appUserListURL,
					method: "GET",
				})
					.then((userList) => {
						commit("updateAppUser", { userList });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		fetchAppUserDetails({ commit }, userID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.appUserDetailURL.replace("{id}", userID),
					method: "GET",
				})
					.then((userDetails) => {
						commit("updateAppUserDetails", userDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		setAppUserDetails({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.appUserDetailURL.replace("{id}", data.id),
					method: "PATCH",
					data: frontendToBackendFormatter(data),
				})
					.then((userDetails) => {
						commit("updateAppUserDetails", userDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		deleteAppUser({ commit }, item) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.appUserDetailURL.replace("{id}", item.id),
					method: "DELETE",
				})
					.then(() => {
						commit("deleteAppUser", item);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
