<template>
	<fade-transition :duration="200">
		<div v-if="showLoader" class="loader" />
	</fade-transition>
</template>
<script>
import { FadeTransition } from "vue2-transitions";

export default {
	name: "loader",
	components: {
		FadeTransition,
	},
	props: {
		displayLoader: {
			type: Boolean,
			description: "Boolean to display the loader or not",
			default: undefined,
		},
	},
	computed: {
		showLoader() {
			if (this.displayLoader != undefined) {
				return this.displayLoader;
			} else {
				return this.$store.state.displayLoader > 0;
			}
		},
	},
};
</script>
<style>
</style>
