import api from "@/api";

const backendToFrontendFormatter = (originalData) => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};
const frontendToBackendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

export default {
	state() {
		return {
			contactDetails: {},
		};
	},
	mutations: {
		updateContactDetails(state, contactDetails) {
			let data = backendToFrontendFormatter(contactDetails);
			state.contactDetails = data;
		},
	},
	actions: {
		fetchContactDetails({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.contactDetailsURL,
					method: "GET",
				})
					.then((contactDetails) => {
						commit("updateContactDetails", contactDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		setSettingsDetails({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.contactDetailsURL,
					method: "PATCH",
					data: frontendToBackendFormatter(data),
				})
					.then((contactDetails) => {
						commit("updateContactDetails", contactDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
