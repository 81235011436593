/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import "es6-promise/auto";
import App from "./App.vue";
import axios from "axios";
import store from "./store/store.js";
import router from "./router";
import "./registerServiceWorker";
import ArgonDashboard from "./plugins/argon-dashboard";
import api from "./api";

Vue.config.productionTip = false;

Vue.use(ArgonDashboard);
axios.defaults.baseURL = api.baseURL;
const token = localStorage.getItem("login-token");
if (token) {
	axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.response.use(undefined, function (err) {
	var statusCode = err.status;
	if (statusCode == undefined) {
		// Server needs to specify CORS headers in the response
		// Basically `ACCESS-CONTROL-ALLOW-ORIGIN: *`
		// Otherwise, these kinda issues happen

		var lineSplit = err.toString().split("\n")[0].split(" ");
		statusCode = lineSplit[lineSplit.length - 1];
	}

	return new Promise(() => {
		//To do err.config && err.config && !err.config.__isRetryRequest
		if (statusCode == 401 && err.config && !err.config.__isRetryRequest) {
			// Got an unauthorized, logout the user

			Vue.prototype.$notify({
				title: "You don't have permission to access this page ,",
				icon: "fa fa-exclamation-triangle",
				type: "danger",
				message: "Contact your administrator",
			});
		}
		throw err;
	});
});

new Vue({
	router,
	render: (h) => h(App),
	store,
}).$mount("#app");
