<template>
<div>
	<div class="dropdown-header noti-title">
		<h6 class="text-overflow m-0">Welcome!</h6>
	</div>
	<!-- <router-link to="/profile" class="dropdown-item">
		<i class="ni ni-single-02"></i>
		<span>My profile</span>
	</router-link> -->
	<div class="dropdown-divider"></div>
	<div @click.prevent="logout" class="dropdown-item">
		<i class="ni ni-user-run"></i>
		<span>Logout</span>
	</div>
</div>
</template>
<script>
export default {
	name: 'mini-nav',
	data() {
		return {}
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
			this.$store.commit('logout');
			this.$router.push('/login');
		}
	}
};
</script>
