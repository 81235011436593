import Vue from "vue";
import Router from "vue-router";
import store from "./store/store.js";

Vue.use(Router);

var availablePaths = [
	{
		path: "/",
		redirect: "/login",
		component: () => import("./views/Login"),
		children: [
			{
				path: "login",
				name: "login",
				productionAvailable: true,
			},
		],
		productionAvailable: true,
	},
	{
		path: "/",
		redirect: "/users",
		component: () => import("./layout/DashboardLayout"),
		meta: { requiresAuth: true } /* LOGIN LOGOUT IMPL MARKER */,
		productionAvailable: true,
		children: [
			{
				path: "users",
				name: "users",
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited. (Not anymore :p webpackChunkName is gone)
				component: () => import("./views/Users"),
			},
			{
				path: "staff",
				name: "staff",
				component: () => import("./views/Staff"),
			},
			{
				path: "app-user",
				name: "app-user",
				component: () => import("./views/AppUser"),
			},
			{
				path: "settings",
				name: "settings",
				component: () => import("./views/Settings.vue"),
			},
		],
	},
	{
		path: "*",
		name: "not found",
		component: () => import("./views/NotFound"),
		productionAvailable: false,
	},
];

const router = new Router({
	mode: "history",
	linkExactActiveClass: "active",
	routes:
		process.env.NODE_ENV === "production"
			? availablePaths.filter((route) => route.productionAvailable)
			: availablePaths,
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// This route requires auth, check if authenticated
		// If not, redirect to login page
		if (!store.getters.isAuthenticated) {
			next({
				path: "/login",
				query: {
					redirect: to.fullPath,
				},
			});
		} else {
			next();
		}
	} else {
		console.log(store.getters.isAuthenticated);
		if (to.name == "login" && store.getters.isAuthenticated) {
			// Redirect to the Dashboard as the user attempted to view the
			// login page while being authenticated already
			next({
				path: "/users",
			});
		} else {
			next();
		}
	}
});

export default router;
