import axios from "axios";

function actionHandler(payload) {
	return new Promise((resolve, reject) => {
		axios(payload)
			.then((response) => {
				let resp = response.data;
				if (response.status >= 200 && response.status < 300) {
					resolve(resp.data);
				} else {
					reject(resp.message);
				}
			})
			.catch((err) => {
				console.error(err);
				reject(getActionErrorMessage(err));
			});
	});
}

function getActionErrorMessage(err) {
	if (err.response?.data?.message) {
		return "Error: " + err.response.data.message;
	} else {
		return err;
	}
}

export default {
	baseURL: "https://excellentdrive.enfono.com/api_excellent/api",

	loginURL: "/auth/login/",
	logoutURL: "/auth/logout/",

	bookingListURL: "/core/booking/list/?limit={limit}&offset={offset}",
	bookingDetailURL: "/core/booking/{id}/",
	userListURL: "/core/staffs/",
	userPostURL: "/core/staffs/",
	userDetailURL: "/core/staffs/{id}/",

	appUserListURL: "/core/app_users/",
	appUserDetailURL: "/core/app_users/{id}/",

	contactDetailsURL: "/core/contact_info/",
	

	staffPermissionDetailsURL: "/core/staffs/{id}/permissions",

	actionHandler,
};
