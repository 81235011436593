import Vue from "vue";
import Vuex from "vuex";
import notificationStore from "./NotificationStore.js";
import accountData from "./AccountStore.js";
import profileStore from "./ProfileStore.js";
import UsersData from './UsersStore/Users.js';
import StaffData from './StaffStore/staff.js';
import AppUser from './AppUser/AppUser'
import Settings from './settings'


Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		UsersData,
		accountData,
		StaffData,
		AppUser,
		Settings
	},
	state: {
		notifications: notificationStore,
		profileData: profileStore,
		displayLoader: 0,
		
	},
	mutations: {
		displayLoader(state, displayLoader) {
			// Loader will be displayed if at least one action is running
			state.displayLoader += (displayLoader ? +1 : -1);
			if (state.displayLoader < 0) {
				state.displayLoader = 0;
			}
		},
		toggleLoader(state) {
			// Not recommended to use 
			state.displayLoader = (state.displayLoader == 0 ? 1 : 0);
		},
	},
	actions: {
	},
	strict: process.env.NODE_ENV !== 'production',

});
store.subscribeAction({
	before: () => {
		store.commit('displayLoader', true);
	},
	after: () => {
		store.commit('displayLoader', false);
	},
	error: () => {
		store.commit('displayLoader', false);
	}
});


// Display the loader when actions are being run


export default store;
