import api from "@/api";

const backendToFrontendFormatter = (originalData) => {
	let user = JSON.parse(JSON.stringify(originalData));

	return user;
};
const frontendToBackendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

export default {
	state() {
		return {
			tableHeaders: ["Name", "Mobile", "Pickup Date", "Pickup Time"],
			tableData: [],
			bookingCount: null,
		};
	},
	mutations: {
		updateBookingList(state, { bookingList }) {
			state.tableData = [];
			bookingList.results.forEach((item) =>
				state.tableData.push(backendToFrontendFormatter(item))
			);
			state.bookingCount = bookingList.count;
			state.isLoaded = true;
		},
		updateBookingDetails(state, userDetails) {
			let data = backendToFrontendFormatter(userDetails);
			state.userDetails = data;
			const index = state.tableData.findIndex(
				(item) => item.id == userDetails.id
			);
			if (index !== -1) {
				state.tableData.splice(index, 1, data);
			}
		},
	},
	actions: {
		fetchBookingList({ commit }, { limit, offset }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.bookingListURL
						.replace("{limit}", limit)
						.replace("{offset}", offset),
					method: "GET",
				})
					.then((bookingList) => {
						commit("updateBookingList", { bookingList });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		setBookingDetails({ commit }, data) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.bookingDetailURL.replace("{id}", data.id),
					method: "PATCH",
					data: frontendToBackendFormatter(data),
				})
					.then((userDetails) => {
						commit("updateBookingDetails", userDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
