import axios from "axios";
import api from "@/api";
// import jwt_decode from "jwt-decode";

function getItem(itemName, defaultValue) {
	let localItem = localStorage.getItem(itemName);
	if (localItem != undefined) {
		return localItem;
	} else {
		return defaultValue;
	}
}

export default {
	state: () => ({
		username: localStorage.getItem('user') || 'Admin',
		token: getItem("login-token"),
		profilePicture:
			"https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png",
	}),
	mutations: {
		login(state, { token }) {
			state.token = token;
		},
		logout(state) {
			state.token = undefined;
			localStorage.removeItem("login-token");
			localStorage.removeItem("user_type");
			localStorage.removeItem("user");
			localStorage.removeItem("user-id");

			delete axios.defaults.headers.common["Authorization"];
		},
	},
	actions: {
		login({ commit }, userData) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.loginURL,
					data: userData,
					method: "POST",
				})
					.then((loginData) => {
						console.log(loginData);
						const token = loginData.token.access;
						localStorage.setItem("user", loginData.user.username);
						localStorage.setItem("user_type", loginData.user.user_type);
						localStorage.setItem("user-id", loginData.user.id);
						localStorage.setItem("login-token", token);

						if (token) {
							axios.defaults.headers.common["Authorization"] =
								"Bearer " + token;
						}
						commit("login", { token });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		logout({ getters }) {
			return new Promise((resolve, reject) => {
				if (!getters.isAuthenticated) {
					resolve();
				} else {
					api.actionHandler({
						url: api.logoutURL,
						method: "POST",
					})
						.then(() => resolve())
						.catch((errMsg) => reject(errMsg));
				}
			});
		},
	},
	getters: {
		isAuthenticated(state) {
			return !!state.token;
		},
	},
};
