import api from "@/api";

const backendToFrontendFormatter = (originalData) => {
	let data = JSON.parse(JSON.stringify(originalData));

	return data;
};
const frontendToBackendFormatter = (originalData) => {
	const data = JSON.parse(JSON.stringify(originalData));
	return data;
};

export default {
	state() {
		return {
			tableHeaders: ["Name", "Mobile", "Email", "Role"],
			tableData: [],
			userDetails: {},
			staffPermissionDetails: {},
		};
	},
	mutations: {
		updateUserList(state, { userList }) {
			state.tableData = [];
			userList.results.forEach((item) =>
				state.tableData.push(backendToFrontendFormatter(item))
			);
			state.isLoaded = true;
		},
		updateUserDetails(state, userDetails) {
			let data = backendToFrontendFormatter(userDetails);
			state.userDetails = data;
			const index = state.tableData.findIndex(
				(item) => item.id === userDetails.id
			);
			if (index !== -1) {
				state.tableData.splice(index, 1, data);
			} else {
				state.tableData.push(data);
			}
		},
		deleteUser(state, userID) {
			const index = state.tableData.findIndex(
				(item) => item.id == userID
			);
			if (index !== -1) {
				state.tableData.splice(index, 1);
			}
		},

		updateStaffPermissionDetails(state, { permissionDetails }) {
			let data = backendToFrontendFormatter(permissionDetails);
			state.staffPermissionDetails = data;
		},
	},
	actions: {
		fetchUserList({ commit }) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.userListURL,
					method: "GET",
				})
					.then((userList) => {
						commit("updateUserList", { userList });
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		fetchUserDetails({ commit }, userID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.userDetailURL.replace("{id}", userID),
					method: "GET",
				})
					.then((userDetails) => {
						commit("updateUserDetails", userDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		setUserDetails({ commit }, data) {
			let edit = false;
			if (data.id !== undefined) {
				edit = true;
			}
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: edit
						? api.userDetailURL.replace("{id}", data.id)
						: api.userPostURL,
					method: edit ? "PATCH" : "POST",
					data: frontendToBackendFormatter(data),
				})
					.then((userDetails) => {
						commit("updateUserDetails", userDetails);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
		deleteUser({ commit }, item) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.userDetailURL.replace("{id}", item.id),
					method: "DELETE",
				})
					.then(() => {
						commit("deleteUser", item);
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		//Staff Permission

		fetchStaffPermissionDetails({ commit }, staffID) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.staffPermissionDetailsURL.replace("{id}", staffID),
					method: "GET",
				})
					.then((permissionDetails) => {
						commit("updateStaffPermissionDetails", {
							permissionDetails,
						});
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},

		setStaffPermissionDetails({ commit }, {data ,staffID}) {
			return new Promise((resolve, reject) => {
				api.actionHandler({
					url: api.staffPermissionDetailsURL.replace("{id}", staffID),
					method: "PATCH",
					data: frontendToBackendFormatter(data),
				})
					.then((permissionDetails) => {
						commit("updateStaffPermissionDetails", {
							permissionDetails,
						});
						resolve();
					})
					.catch((errMsg) => reject(errMsg));
			});
		},
	},
};
